/* stylelint-disable order/order */
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

@mixin titles-font-sizes {
  .Title {
    @include breakpoints.bpk-breakpoint-desktop-only {
      @include typography.bpk-hero-3;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      @include typography.bpk-hero-4;
    }

    @include breakpoints.bpk-breakpoint-small-tablet {
      @include typography.bpk-hero-5;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      @include typography.bpk-hero-6;
    }
  }

  .Subtitle {
    @include breakpoints.bpk-breakpoint-small-tablet {
      @include typography.bpk-editorial-3;
    }
  }
}

@mixin titles-align {
  // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
  /* stylelint-disable-next-line no-duplicate-selectors,
      scss/selector-no-redundant-nesting-selector */
  & {
    text-align: center;
  }

  @include breakpoints.bpk-breakpoint-mobile {
    text-align: left;

    @include utils.bpk-rtl {
      text-align: right;
    }
  }
}

@mixin titles-margin {
  .TitleTopMargin {
    margin-top: tokens.bpk-spacing-sm();
  }

  .TitleBottomMargin {
    margin-bottom: tokens.bpk-spacing-base();
  }
}

@mixin header-flex-column {
  .Header {
    display: flex;
    flex-direction: column;
  }
}

@mixin titles-padding {
  // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
  /* stylelint-disable-next-line no-duplicate-selectors,
      scss/selector-no-redundant-nesting-selector */
  & {
    padding-top: tokens.bpk-spacing-xxxl();
    padding-bottom: tokens.bpk-spacing-xxxxl();
  }

  @include breakpoints.bpk-breakpoint-desktop-only {
    padding-top: tokens.bpk-spacing-xxl();
    padding-bottom: tokens.bpk-spacing-xxxl();
  }

  @include breakpoints.bpk-breakpoint-tablet {
    padding-top: tokens.bpk-spacing-xxl();
    padding-bottom: tokens.bpk-spacing-xxxl();
  }

  @include breakpoints.bpk-breakpoint-small-tablet {
    padding-top: tokens.bpk-spacing-xxl();
    padding-bottom: tokens.bpk-spacing-xxxl();
  }

  @include breakpoints.bpk-breakpoint-mobile {
    padding-top: tokens.bpk-spacing-lg();
    padding-bottom: tokens.bpk-spacing-xl();
  }
}

@mixin responsive-image {
  .HeroImage {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include breakpoints.bpk-breakpoint-small-tablet {
      > div {
        padding-bottom: 100% !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
}

// stylelint-disable unit-disallowed-list, order/order
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../mixins';
@use 'utils';

$underlay-height: 56 * tokens.$bpk-one-pixel-rem + tokens.bpk-spacing-xxxl();

.NoHeroUnderlay {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: $underlay-height;
  background-color: tokens.$bpk-surface-hero-day;
}

.NoHeroSpacer {
  background-color: tokens.$bpk-surface-hero-day;
}

.PageHeader {
  position: relative;
  background-color: tokens.$bpk-surface-hero-day;
  color: tokens.$bpk-canvas-contrast-day;

  &__Eyebrow {
    .TextContainer {
      .FixedWidth {
        @include mixins.custom-pages-grid;
      }

      @include utils.header-flex-column;
      @include utils.titles-align;
      @include utils.titles-font-sizes;
      @include utils.titles-margin;
      @include utils.titles-padding;
    }

    .ImageContainer {
      @include utils.responsive-image;

      // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
      /* stylelint-disable-next-line no-duplicate-selectors, scss/selector-no-redundant-nesting-selector */
      & {
        background: linear-gradient(
          tokens.$bpk-surface-hero-day 0 33%,
          tokens.$bpk-color-white 33% 100%
        );
      }

      .FixedWidth {
        @include mixins.custom-pages-grid;
      }
    }
  }

  &__Column {
    .ImageContainer {
      @include utils.responsive-image;
    }

    @include breakpoints.bpk-breakpoint-above-tablet {
      z-index: 0;
      margin-top: -$underlay-height;

      .HeaderContent {
        @include mixins.custom-pages-grid;

        .ColumnLayout {
          display: flex;
          gap: tokens.bpk-spacing-xl();

          .TextContainer {
            display: flex;
            margin-top: $underlay-height;
            padding-bottom: tokens.bpk-spacing-xl();
            align-items: center;
            flex: 1;
          }

          .ImageContainer {
            position: relative;
            top: $underlay-height;
            flex: 1;

            .HeroImage > div {
              min-height: 586 * tokens.$bpk-one-pixel-rem;
            }
          }
        }
      }
    }

    @include breakpoints.bpk-breakpoint-tablet {
      .ColumnLayout {
        .TextContainer {
          @include utils.titles-align;
          @include utils.titles-font-sizes;
          @include utils.titles-margin;
          @include utils.titles-padding;

          .FixedWidth {
            @include mixins.custom-pages-grid;
          }
        }

        .ImageContainer {
          background: linear-gradient(
            tokens.$bpk-surface-hero-day 0 33%,
            tokens.$bpk-color-white 33% 100%
          );

          .FixedWidth {
            @include mixins.custom-pages-grid;
          }
        }
      }
    }
  }

  &__InHero {
    background: linear-gradient(
      tokens.$bpk-surface-hero-day 0 33%,
      tokens.$bpk-color-white 33% 100%
    );

    .HeaderContent {
      @include mixins.custom-pages-grid;
    }

    .ImageContainer {
      @include utils.responsive-image;

      // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
      /* stylelint-disable-next-line no-duplicate-selectors, scss/selector-no-redundant-nesting-selector */
      & {
        position: relative;
      }

      .TextContainer {
        @include utils.header-flex-column;
        @include utils.titles-font-sizes;
        @include utils.titles-margin;

        // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
        /* stylelint-disable-next-line no-duplicate-selectors, scss/selector-no-redundant-nesting-selector */
        & {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          padding: tokens.bpk-spacing-lg();
          transform: translateY(-50%);
          text-align: center;
        }
      }
    }
  }
}

.AuthorContainer {
  .FixedWidth {
    @include mixins.custom-pages-grid;
  }

  // https://sass-lang.com/documentation/breaking-changes/mixed-decls/
  /* stylelint-disable-next-line no-duplicate-selectors, scss/selector-no-redundant-nesting-selector */
  & {
    display: flex;
    height: $underlay-height;
    align-items: center;
  }

  &--no-author {
    @include breakpoints.bpk-breakpoint-tablet {
      display: none;
    }
  }
}

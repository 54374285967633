@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.Author {
  display: flex;
  align-items: center;
  gap: tokens.bpk-spacing-base();

  .AuthorImage {
    width: tokens.bpk-spacing-xxxl();
    height: tokens.bpk-spacing-xxxl();
    border-radius: 100%;
    object-fit: cover;
  }
}
